@import "~cookieconsent/build/cookieconsent.min.css";
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/css/font-awesome.css";

/* source-code-pro-600 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face
{
	font-family: "Source Code Pro";
	font-style: normal;
	font-weight: 600;
	src: url("./fonts/source-code-pro-v22-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot"); /* IE9 Compat Modes */
	src:
		local(""),
		url("./fonts/source-code-pro-v22-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
		url("./fonts/source-code-pro-v22-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff2") format("woff2"), /* Super Modern Browsers */
		url("./fonts/source-code-pro-v22-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff") format("woff"), /* Modern Browsers */
		url("./fonts/source-code-pro-v22-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.ttf") format("truetype"), /* Safari, Android, iOS */
		url("./fonts/source-code-pro-v22-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.svg#SourceCodePro") format("svg"); /* Legacy iOS */
}

body
{
	background: linear-gradient(rgb(255 225 255 / 60%), rgb(255 255 255 / 90%)), url("tng-background.jpg");
	background-color: white;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	font-family: "Source Code Pro", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6
{
	font-family: "Source Code Pro", monospace;
}

ul
{
	list-style-position: inside;
}

.header-image
{
	padding-top: 3.5rem;
}

.col
{
	width: 20% !important;
}

.content-section
{
	padding-top: 7.5rem;
	padding-bottom: 7.5rem;
}

.blue-section
{
	padding-top: 7.5rem;
	color: white;
}

.white-section
{
	padding-top: 0;
	padding-bottom: 3.5rem;
	color: black;
	background-color: #ffff;
}

.gray-section
{
	color: white;
	background-color: #2d2d2d;
	padding-bottom: 7.5rem;
}

.white-background
{
	background-image: url("lackierung1.jpg");
	height: 15rem;
	background-size: cover;
}

.gray-background
{
	background-image: url("lackierung2.jpg");
	height: 8rem;
	background-size: cover;
}

.bg-primary
{
	background-color: #00184a !important;
}

.btn-primary
{
	background-color: #00184a !important;
	border-color: #00184a !important;
}

p
{
	font-size: 1.1rem;
	font-weight: 300;
}

.callout-section
{
	padding: 15rem 0;
	background-color: #212529;
	background-image: url("map-image.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.callout-section-2
{
	padding: 6rem 0;
	background-color: #212529;
	background-image: url("spacer-8.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.callout-section h2
{
	font-size: 3.5rem;
	font-weight: 700;
	display: block;
	max-width: 45rem;
	color: white;
}

.img-spacer
{
	height: 20rem;
	background-size: cover;
	background-position: center;
}

.img-spacer h1
{
	padding-top: 8.25rem;
	font-size: 3.5rem;
	color: white;
}

@media (max-width: 576px)
{
	h1
	{
		font-size: 2rem;
	}

	.white-background
	{
		height: 5rem;
	}

	.img-spacer
	{
		height: 10rem;
	}

	.img-spacer h1
	{
		padding-top: 4rem;
		font-size: 2rem;
	}

	.content-section
	{
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

.portfolio-item
{
	margin-bottom: 30px;
}

.pagination
{
	margin-bottom: 30px;
}

.page-header
{
	padding-bottom: 9px;
	margin: 40px 0 20px;
	border-bottom: 1px solid black;
	text-shadow: 2px 2px 4px white;
}

/* partner */
.partner-section
{
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.img-full-width
{
	width: 100%;
}

.custom-control
{
	font-size: 1.35rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

/* bewerbungsforular */

section#contact
{
	background-position: center;
	background-repeat: no-repeat;
}

section#contact .form-group
{
	margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea
{
	padding: 20px;
}

section#contact .form-group input.form-control
{
	height: auto;
}

section#contact .form-group textarea#message
{
	height: 21.2rem;
}

section#contact .form-group textarea#info
{
	height: 9.8rem;
}

section#contact .form-control:focus
{
	border-color: #0026ff;
	box-shadow: none;
}

section#contact ::placeholder
{
	font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	color: #444;
}

section#contact :placeholder
{
	/* Firefox 18- */
	font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	color: #444;
}

section#contact .text-danger
{
	color: #e74c3c;
}

.help-block
{
	color: red;
}

.custom-control-label::before,
.custom-control-label::after
{
	/* top: .8rem; */
	width: 1.35rem;
	height: 1.35rem;
}

/* ---------------- timeline ------------------------- */

#portfolio .portfolio-item
{
	right: 0;
	margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link
{
	position: relative;
	display: block;
	max-width: 400px;
	margin: 0 auto;
	cursor: pointer;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover
{
	position: absolute;
	width: 100%;
	height: 100%;
	transition: all ease 0.5s;
	opacity: 0;
	background: rgb(254 209 54 / 90%);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover
{
	opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content
{
	font-size: 20px;
	position: absolute;
	top: 50%;
	width: 100%;
	height: 20px;
	margin-top: -12px;
	text-align: center;
	color: white;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i
{
	margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4
{
	margin: 0;
}

#portfolio .portfolio-item .portfolio-caption
{
	max-width: 400px;
	margin: 0 auto;
	padding: 25px;
	text-align: center;
	background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4
{
	margin: 0;
	text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p
{
	font-size: 16px;
	font-style: italic;
	margin: 0;
	font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#portfolio *
{
	z-index: 2;
}

@media (min-width: 767px)
{
	#portfolio .portfolio-item
	{
		margin: 0 0 30px;
	}
}

.portfolio-modal .modal-dialog
{
	max-width: none;
	height: 100%;
	margin: 0;
}

.portfolio-modal .modal-content
{
	min-height: 100%;
	padding: 100px 0;
	text-align: center;
	border: none;
	border-radius: 0;
	background-clip: border-box;
	box-shadow: none;
}

.portfolio-modal .modal-content h2
{
	font-size: 3em;
	margin-bottom: 15px;
}

.portfolio-modal .modal-content p
{
	margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro
{
	font-size: 16px;
	font-style: italic;
	margin: 20px 0 30px;
	font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.portfolio-modal .modal-content ul.list-inline
{
	margin-top: 0;
	margin-bottom: 30px;
}

.portfolio-modal .modal-content img
{
	margin-bottom: 30px;
}

.portfolio-modal .modal-content button
{
	cursor: pointer;
}

.portfolio-modal .close-modal
{
	position: absolute;
	top: 25px;
	right: 25px;
	width: 75px;
	height: 75px;
	cursor: pointer;
	background-color: transparent;
}

.portfolio-modal .close-modal:hover
{
	opacity: 0.3;
}

.portfolio-modal .close-modal .lr
{
	/* Safari and Chrome */
	z-index: 1051;
	width: 1px;
	height: 75px;
	margin-left: 35px;

	/* IE 9 */
	transform: rotate(45deg);
	background-color: #212529;
}

.portfolio-modal .close-modal .lr .rl
{
	/* Safari and Chrome */
	z-index: 1052;
	width: 1px;
	height: 75px;

	/* IE 9 */
	transform: rotate(90deg);
	background-color: #212529;
}

.portfolio-modal .modal-backdrop
{
	display: none;
	opacity: 0;
}

/* Shadows */
.card
{
	box-shadow: 4px 4px 4px;
}

.thumbnail
{
	box-shadow: 4px 4px 4px;
}

.well
{
	box-shadow: 4px 4px 4px;
}

.jumbotron
{
	box-shadow: 4px 4px 4px;
}

.subheading
{
	color: #0026ff;
}

/* footer */

footer
{
	background-color: #343a40;
	color: white;
	padding-top: 30px;
}

footer .footer-copyright
{
	background-color: #222;
	padding-top: 3px;
	padding-bottom: 3px;
	text-align: center;
}

footer .row
{
	margin-bottom: 60px;
}

footer .navbar-brand
{
	margin-top: 45px;
	height: 65px;
}

footer .footer-copyright p
{
	margin: 10px;
	color: #ccc;
}

footer ul
{
	list-style-type: none;
	padding-left: 0;
	line-height: 1.7;
}

footer h5
{
	font-size: 18px;
	color: white;
	font-weight: bold;
	margin-top: 30px;
}

footer h2 a
{
	font-size: 50px;
	text-align: center;
	color: #fff;
}

footer a
{
	color: #d2d1d1;
	text-decoration: none;
}

footer a:hover,
footer a:focus
{
	text-decoration: none;
	color: white;
}

footer .social-networks
{
	text-align: center;
	padding-top: 30px;
	padding-bottom: 16px;
}

footer .social-networks a
{
	font-size: 32px;
	color: #f9f9f9;
	padding: 10px;
	transition: 0.2s;
}

footer .social-networks a:hover
{
	text-decoration: none;
}

footer .facebook:hover
{
	color: #0077e2;
}

footer .rss:hover
{
	color: #f56505;
}

footer .youtube:hover
{
	color: #c4302b;
}

footer .steam:hover
{
	color: #1a3e54;
}

footer .truckersmp:hover
{
	color: #b92025;
}

footer .btn
{
	color: white;
	background-color: #011748;
	border-radius: 20px;
	border: none;
	width: 150px;
	display: block;
	margin: 0 auto;
	margin-top: 10px;
	line-height: 25px;
}

@media screen and (max-width: 767px)
{
	.ausblenden
	{
		display: none;
	}

	footer
	{
		text-align: center;
	}
}

/* social */
#social
{
	padding-top: 50px;
}

#social a
{
	color: white;
	text-decoration: none;
}

.nav-link
{
	font-size: 1.2rem;
}

.dropdown-item
{
	font-size: 1.2rem;
}

.showcase .showcase-text
{
	padding: 2rem;
}

.showcase .showcase-img
{
	min-height: 25rem;
	background-size: cover;
	background-position: center;
}

@media (min-width: 768px)
{
	.showcase .showcase-text
	{
		padding: 7rem;
	}
}

.showcase
{
	background-color: white;
}

.showcase-text hr
{
	border-color: #00184a;
	border-width: 0.25rem;
	width: 30%;
}

.card-event
{
	display: flex;
	background-color: #818181a9;
	border-radius: 0.35rem;
	flex-direction: column;
	font-size: 1.5rem !important;
}

.image-hover
{
	transition: 0.8s ease-in-out;
	backface-visibility: hidden;
}

.image-hover:hover
{
	opacity: 0.5;
}

.zoom
{
	transition: transform 0.2s;
	margin: 0 auto;
}

.zoom:hover
{
	transform: scale(1.2);
}

.info
{
	position: relative;
	transition: 0.5s;
}

.info:hover
{
	padding-right: 25px;
}

/* ----------------------------------
	Macbook pro mockup from:
	http://jaredhardy.com/minimal-devices/

---------------------------------- */

.md-macbook-pro
{
	display: block;
	width: 55.3125em;
	height: 31.875em;
	font-size: 13px;
	margin: 0 auto;

	@media (max-width: 1199px)
	{
		font-size: 11px;
	}

	@media (max-width: 1024px)
	{
		font-size: 10px;
	}

	@media (max-width: 767px)
	{
		font-size: 7px;
	}

	@media (max-width: 320px)
	{
		font-size: 5px;
	}
}

.md-macbook-pro .md-lid
{
	width: 45em;
	height: 30.625em;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
	border-radius: 1.875em;
	border: solid 0.1875em #cdced1;
	background: #131313;
}

.md-macbook-pro .md-camera
{
	width: 0.375em;
	height: 0.375em;
	margin: 0 auto;
	position: relative;
	top: 1.0625em;
	background: #000;
	border-radius: 100%;
	box-shadow: inset 0 -1px 0 rgb(255 255 255 / 25%);
}

.md-macbook-pro .md-camera::after
{
	content: "";
	display: block;
	width: 0.125em;
	height: 0.125em;
	position: absolute;
	left: 0.125em;
	top: 0.0625em;
	background: #353542;
	border-radius: 100%;
}

.md-macbook-pro .md-screen
{
	width: 42.25em;
	height: 26.375em;
	margin: 0 auto;
	position: relative;
	top: 2.0625em;
	background: #1d1d1d;
	background: #fff;
	overflow: hidden;
}

.md-macbook-pro .md-screen img
{
	width: 100%;
}

.md-macbook-pro .md-base
{
	width: 100%;
	height: 0.9375em;
	position: relative;
	top: -0.75em;
	background: #c6c7ca;
}

.md-macbook-pro .md-base::after
{
	content: "";
	display: block;
	width: 100%;
	height: 0.5em;
	margin: 0 auto;
	position: relative;
	bottom: -0.1875em;
	background: #b9babe;
	border-radius: 0 0 1.25em 1.25em;
}

.md-macbook-pro .md-base::before
{
	content: "";
	display: block;
	width: 7.6875em;
	height: 0.625em;
	margin: 0 auto;
	position: relative;
	background: #a6a8ad;
	border-radius: 0 0 0.625em 0.625em;
}

.md-macbook-pro.md-glare .md-lid::after
{
	content: "";
	display: block;
	width: 50%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 0 1.25em 0 0;
	background: linear-gradient(37deg, rgb(255 255 255 / 0%) 50%, rgb(247 248 240 / 2.5%) 50%, rgb(250 245 252 / 8%));
	background: linear-gradient(53deg, rgb(255 255 255 / 0%) 50%, rgb(247 248 240 / 2.5%) 50%, rgb(250 245 252 / 8%));
}
